
import { Options, Vue, prop } from "vue-class-component";
import { PropType, ref } from "vue";
import LpInputSearch from "@/app/ui/components/lp-input-search/index.vue";
import SelectSearch from "@/app/ui/components/select-search/index.vue";

class Props {
  options = prop<any[]>({
    default: [],
    type: Array
  });
  modelValue = prop<any>({
    required: true
  });
  customer = prop<any>({
    required: true
  });
  dataSearch = prop<any[]>({
    default: [],
    type: Array
  });
  onSearch = prop<Function>({
    default: Function as PropType<() => void>,
    type: Function
  });
  phone = prop<string>({
    default: "",
    type: String
  });
  code = prop<string>({
    default: "",
    type: String
  });
  error = prop<boolean>({
    default: false,
    type: Boolean
  });
  errorCaption = prop<string>({
    default: "Error",
    type: String
  });
  maxLength = prop<number>({
    default: 14,
    type: Number
  });
  format = prop<any>({
    default: null,
    type: Function
  });
  placeholder = prop<string>({
    default: "",
    type: String
  });
  prefixWidth = prop<string>({
    default: "w-20",
    type: String
  });
  disabled = prop<boolean>({
    default: false,
    type: Boolean
  });
  disabledUserDropdown = prop<boolean>({
    default: false,
    type: Boolean
  });
  isLoading = prop<boolean>({
    default: false,
    type: Boolean
  });
  isShowErrorMessage = prop<boolean>({
    default: true,
    type: Boolean
  });
  isShowIconEmpty = prop<boolean>({
    default: false,
    type: Boolean
  });
  errorMessage = prop<string>({
    default: "Apa yang Anda cari tidak ditemukan atau tidak ada.",
    type: String
  });
  keyValue = prop<string>({
    default: "value",
    type: String
  });
  allEnabled = prop<boolean>({
    default: false,
    type: Boolean
  });
  keyName = prop<string>({
    default: "name",
    type: String
  });
}

@Options({
  emits: ["change", "focus", "setValue", "pasteText", "update:code","editItem","update:customer"],
  components: { LpInputSearch, SelectSearch },
  watch: {
    "searchCountryModel": function() {
      if (this.searchCountryModel) {
        this.codeProp = this.searchCountryModel.code;
        this.onChooseSearch(this.searchCountryModel);
      }
    },
  }
})
export default class SelectGroup extends Vue.with(Props) {

  onFilter = false;
  selectedIndex = -1;
  isCustomerMenuOpen = false;
  searchCountryString = "";
  searchCountryModel = {
    code: "",
    flag: "",
    name: "",
    nationalPrefix: "",
  };
  
  isOpenSearch = false;

  filterSearch =
    typeof this.modelValue === "object"
      ? this.modelValue[this.keyName]
      : this.modelValue;

  get codeProp() {
    return this.code;
  }

  set codeProp(value) {
    this.$emit('update:code', value);
  }

  onOpenSearch() {
    this.isCustomerMenuOpen = false;
    this.isOpenSearch = !this.isOpenSearch;
    setTimeout(() => {
      if ((this.$refs['searchCountryRef'] as any)) {
        (this.$refs['searchCountryRef'] as any).onOpenSelect(true);
      }
    }, 200);
  }
  onCloseSearch() {
    this.isOpenSearch = false;
    this.isCustomerMenuOpen = false;
  }
  onChooseSearch(item: any) {
    this.$emit("setValue", item);
  }
  formatText(e: any) {
    e.target.value = this?.format(e.target.value);
    this.$emit("change", e.target.value);
  }
  onFocus(e: any) {
    this.onCloseSearch();
    this.$emit("focus", e);
    this.onOpenSelect(true);
  }
  pasteText() {
    this.$emit("pasteText");
  }

  editItem(item: any) {
    this.onCloseSearch();
    this.$emit('editItem', item);
  }

  get dataOptions() {
    this.selectedIndex = this.options.findIndex((item: any) =>
      this.isSelected(item)
    );
    if (!this.onFilter) {
      return this.options;
    }
    const tempOptions = this.options.filter((option: any) => {
      return option[this.keyName]
        ? option[this.keyName]
            .toLowerCase()
            .includes(this.filterSearch.toLowerCase())
        : option.toLowerCase().includes(this.filterSearch);
    });
    if (this.allEnabled && !this.filterSearch) {
      const allObject: any = {};
      allObject[this.keyName] = "All";
      allObject[this.keyValue] = "all";
      tempOptions.unshift(allObject);
    }
    this.selectedIndex = tempOptions.findIndex((item: any) =>
      this.isSelected(item)
    );
    return tempOptions;
  }

  // logic for selected item
  isSelected(item: any) {
    return this.customer?.[this.keyValue]
      ? this.customer?.[this.keyValue] === item?.[this.keyValue]
      : this.customer === item || this.customer === item?.[this.keyValue] || this.customer?.[this.keyValue] === item?.[this.keyValue];
  }

  onChooseSelect(item: any) {
    if (this.isSelected(item)) {
      this.$emit("update:customer", {});
      this.$emit("change", "");
      this.filterSearch = "";
    } else {
      this.$emit("update:customer", item);
      this.$emit("change", item[this.keyName]);
      this.filterSearch = item[this.keyName] || item;
    }
    this.onOpenSelect(false);
  }

  onOpenSelect(value: boolean) {
    this.isOpenSearch = false;
    if (!value) {
      this.$emit("close", "");
      this.$emit("blur", "");
    }
    this.isCustomerMenuOpen = value;
  }

  highlightMatch(text: string): string {
    const searchText = this.code + this.phone;
    if (!searchText) {
      return text;
    }
    const escapedSearchText = searchText.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
    const regex = new RegExp(`(${escapedSearchText})`, "gi");
    const boldText = text.replace(regex, "<strong>$1</strong>");
    return boldText;
  }
}
