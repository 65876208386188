
import { Options, Vue } from "vue-class-component";
import Title from "@/app/ui/components/data-wrapper/title.vue";
import Tooltip from "@/app/ui/components/tooltip/index.vue";
import InputDimension from "@/app/ui/components/input-dimension/index.vue";
import Checkbox from "primevue/checkbox";
import formatInputMoney from "@/app/infrastructures/misc/common-library/FormatInputMoney";
import formattedNPWP from "@/app/infrastructures/misc/common-library/FormattedNPWP";
import isUrlUpdateVersion from "@/app/infrastructures/misc/common-library/IsUrlUpdateVersion";
import maxLengthPhoneNumber from "@/app/infrastructures/misc/common-library/MaxLengthPhoneNumber";
import prefixAlgoEstimationShow from "@/app/infrastructures/misc/common-library/PrefixAlgoEstimationShow";
import removeNewLineWhitespace from "@/app/infrastructures/misc/common-library/RemoveNewLineWhitespace";
// import Calculation from "./edit-payment-detail/calculation.vue";
// import Uncalculation from "./edit-payment-detail/uncalculation.vue";
import { CommodityController } from "@/app/ui/controllers/CommodityController";
import { BookingController } from "@/app/ui/controllers/BookingController";
import debounce from "lodash/debounce";
import { LocationController } from "@/app/ui/controllers/LocationController";
import { AccountController } from "@/app/ui/controllers/AccountController";
import { ShipmentBookingController } from "@/app/ui/controllers/ShipmentBookingController";
import ModalErrorNotAllowEdit from "@/app/ui/views/shipment/booking/component/modal-error-not-allow-edit.vue";
import { maxLengthWeight } from "../component/modules";
import ErrorMessageBooking from "../component/error-message/destination-vendor-ninja.vue";
import LpInputSearch from "@/app/ui/components/lp-input-search/index.vue";
import WarningSttCod from "@/app/ui/views/shipment/booking/component/error-message/warning-stt-cod.vue";
import { MainAppController } from "@/app/ui/controllers/MainAppController";
import { RequestListCommodity } from "@/data/payload/api/CommodityApiRequest";
import SelectPhoneCountry from "../component/select-phone-country.vue";
import SectionSTTManualReference from "./components/section-stt-manual-reference.vue";
import SectionSenderDetail from "./components/section-sender-detail.vue";
import SectionRecipientDetail from "./components/section-recipient-detail.vue";
import SectionPackageDetail from "./components/section-package-detail.vue";
import SectionAdditionalInfoInterpack from "./components/section-additional-info-interpack.vue";
import SectionFooterBooking from "./components/footer-booking/index.vue";

import {
  codePhoneCountry,
  maxKg,
  totalGrossWeightSikat,
  totalGrossWeightNonSikat,
  promoPrefix,
  volumeWeightForProduct,
  prefixDisableDimensionC1,
  gapValidationCHWeightTokped,
} from "@/app/ui/views/shipment/booking/booking-utils";
import { ProductConfigurationRuleList } from "@/domain/entities/Product";
import { ProductController } from "@/app/ui/controllers/ProductController";
import { ProductConfigurationRuleRequestParams } from "@/data/payload/api/ProductApiRequest";
import formatInputMoneyWithDecimal from "@/app/infrastructures/misc/common-library/FormatInputMoneyWithDecimal";
import { FlagsMedusa } from "@/feature-flags/flags-misc-medusa";
import BannerProductType from "@/app/ui/views/shipment/booking/component/banner-product-type.vue";
import ProductPoster from "../component/product-poster/index.vue";
import { FlagsFeatureShipmentC1 } from "@/feature-flags/flags-shipment-c1";
import AdditionalInfo from "../component/additional-info/index.vue";
import RadioButton from "primevue/radiobutton";
import {
  DeleteHydraFileRequest,
  EditBooking,
  UploadHydraFileRequest,
} from "@/data/payload/api/BookingRequest";
import formatPriceRP from "@/app/infrastructures/misc/common-library/FormatPriceRP";
import formatPriceRM from "@/app/infrastructures/misc/common-library/FormatPriceRM";
import {
  formatPriceWithoutCurrency,
  parsingErrorResponse,
  storeDatatoLocalStorage,
} from "@/app/infrastructures/misc/Utils";
import Woodpacking from "../component/woodpacking/index.vue";
import SectionUploadPhotoForWeightAdjustment from "../component/section-upload-photo-for-weight-adjusment/index.vue";
import { UploadMultiple } from "@/domain/entities/MainApp";
import { HeavyWeightSurchargeController } from "@/app/ui/controllers/HeavyWeightSurchargeController";
import { DistrictData } from "@/domain/entities/Location";

interface Diskon {
  isDiscount: boolean;
  totalTariff: number;
  totalAfterDiscount: number;
  totalBeforeDiscount: number;
}

@Options({
  components: {
    WarningSttCod,
    Title,
    Tooltip,
    InputDimension,
    Checkbox,
    // Calculation,
    // Uncalculation,
    ModalErrorNotAllowEdit,
    ErrorMessageBooking,
    LpInputSearch,
    SelectPhoneCountry,
    BannerProductType,
    ProductPoster,
    AdditionalInfo,
    RadioButton,
    Woodpacking,
    SectionUploadPhotoForWeightAdjustment,
    SectionSTTManualReference,
    SectionSenderDetail,
    SectionRecipientDetail,
    SectionPackageDetail,
    SectionAdditionalInfoInterpack,
    SectionFooterBooking,
  },
  beforeRouteLeave(to: any, from: any, next: any) {
    if (to.name === "login") {
      next();
    }
    if (
      !this.openSuccess &&
      !this.isModalNotAllowEdit &&
      !this.NotAllowEditC1Shipment
    ) {
      this.onOpenClosePage(to);
      if (this.answer) {
        this.answer = false;
        next();
      } else {
        next(false);
      }
    } else {
      BookingController.setShowModalSuccess(false);
      next();
    }
  },
  computed: {
    getTitle() {
      return this.$route.meta.name;
    },
  },
})
export default class Edit extends Vue {
  get isEnableCodBooking() {
    return FlagsMedusa.config_new_cod_booking_enable.isEnabled();
  }

  get isCodRetailApplied() {
    return this.manualBooking.sttIsCod || this.manualBooking.sttIsDfod;
  }

  get maxAddressLength() {
    return FlagsMedusa.config_max_address.getValue();
  }

  //action
  openSave() {
    return BookingController.showModalManualBooking;
  }

  closeModalBooking() {
    BookingController.setShowModalManualBooking(false);
  }

  get titleErrorspesification() {
    return BookingController.titleErrorspesification;
  }

  booking() {
    this.isSubmit = true;
    BookingController.editBooking(
      new EditBooking({
        id: this.payload.id,
        isMixpack: this.payload.isMixpack,
        sttNoRefExternal: this.payload.sttNoRefExternal,
        sttTaxNumber: this.payload.sttTaxNumber,
        sttGoodsEstimatePrice: this.payload.sttGoodsEstimatePrice,
        sttGoodsStatus: this.payload.sttGoodsStatus,
        sttOriginCityId: this.payload.sttOriginCityId,
        sttOriginDistrictId: this.payload.sttOriginDistrictId,
        sttDestinationCityId: this.payload.sttDestinationCityId,
        sttDestinationDistrictId: this.payload.sttDestinationDistrictId,
        sttSenderName: this.payload.sttSenderName,
        sttSenderPhone: this.payload.sttSenderPhone,
        sttSenderAddress: this.payload.sttSenderAddress,
        sttIsSaveSender: this.payload.sttIsSaveSender,
        sttRecipientName: this.payload.sttRecipientName,
        sttRecipientAddress: this.payload.sttRecipientAddress,
        sttRecipientAddressType: this.payload.sttRecipientAddressType,
        sttRecipientPhone: this.payload.sttRecipientPhone,
        sttIsSaveRecipient: this.payload.sttIsSaveRecipient,
        sttInsuranceType: this.payload.sttInsuranceType,
        sttProductType: this.payload.sttProductType,
        sttCommodityCode: this.payload.sttCommodityCode,
        sttClientSttId: this.payload.sttClientSttId,
        sttVendorSttId: this.payload.sttVendorSttId,
        sttIsCod: this.payload.sttIsCod,
        sttIsDfod: this.payload.sttIsDfod,
        sttWeightAttachFiles: this.payload.sttWeightAttachFiles,
        sttAttachFiles: this.payload.sttAttachFiles,
        sttCommodityDetail: this.payload.sttCommodityDetail,
        codHandling: this.payload.codHandling,
        sttIsDo: this.payload.sttIsDo,
        sttIsWoodpacking: this.payload.sttIsWoodpacking,
        sttPieces: this.payload.sttPieces,
        sttPiecePerPack: this.payload.sttPiecePerPack,
        sttNextCommodity: this.payload.sttNextCommodity,
        sttCodAmount: this.payload.sttCodAmount,
        postalCodeDestination: this.payload.postalCodeDestination,
        sttIsPad: this.payload.sttIsPad,
        sttRecipientEmail: this.payload.sttRecipientEmail,
        sttKtpImage: this.payload.sttKtpImage,
        sttTaxImage: this.payload.sttTaxImage
      })
    );
  }

  get isErrorSpesificRate() {
    return BookingController.errorSpesificByRate;
  }

  get isErrorAdd() {
    return BookingController.isErrorEditBooking;
  }

  get errorCause() {
    return BookingController.isErrorCauseEditBooking;
  }

  closeModalError() {
    BookingController.setErrorEditBooking(false);
    if (this.isErrorEmbargo) {
      this.updateStatusErrorEmbargo(true);
    }
  }

  get openSuccess() {
    return BookingController.showModalSuccess;
  }

  get isModalNotAllowEdit() {
    return !this.manualBooking.sttIsAllowEdit;
  }

  get isShipmentC1() {
    return this.manualBooking.sttShipmentId.match(/^C1|^C2/gi);
  }

  get NotAllowEditC1Shipment() {
    if (AccountController.accountData.isPosAccount) {
      return (
        this.isShipmentC1 &&
        !prefixDisableDimensionC1(
          String(AccountController.accountData.account_id)
        )
      );
    }

    if (AccountController.accountData.isConsoleAccount && this.isShipmentC1) {
      return true;
    }

    return this.isModalNotAllowEdit;
  }

  onCloseSuccess() {
    this.goBack();
    BookingController.setShowModalSuccess(false);
  }

  get messageSuccess() {
    return BookingController.messageSuccess;
  }

  isEditTerminated = false;
  newPath = "";
  answer = false;

  onOpenClosePage(to: any) {
    this.isEditTerminated = true;
    this.newPath = to.path;
  }

  handlerClose() {
    this.isEditTerminated = false;
    this.answer = true;
    this.$router.push(this.newPath);
  }

  handlerCancelClose() {
    this.answer = false;
    this.isEditTerminated = false;
  }

  goBack() {
    this.$router.push(`/shipment/${this.customPath}`);
  }

  get customPath() {
    return this.$route.meta.customPath
      ? this.$route.meta.customPath
      : "booking";
  }

  get reRenderManualBooking() {
    return BookingController.reRenderManualBooking;
  }

  // tooltip
  isOpenTooltipManualSTT = false;
  isOpenTooltipShipmentID = false;
  isOpenTooltipStatusGoods = false;
  isOpenTooltipInsurance = false;
  isOpenTooltipTax = false;
  isOpenTooltipWeight = false;
  isOpenTooltipPiecePerPack = false;
  isOpenTooltipNextCommodity = false;

  error = {
    sttManual: false,
    productName: false,
    taxNumber: false,
    phoneSender: false,
    phoneCodeSender: false,
    phoneCharMinimalSender: false,
    phoneReceiver: false,
    phoneCodeReceiver: false,
    phoneCharMinimalReceiver: false,
    estimationPrice: false,
    grossWeight: false,
    maxPerPieces: false,
    maxTotalGrossWeight: false,
    product: false,
    insurance: false,
    addressSender: false,
    addressReceiver: false,
    nextCommodity: false,
  };

  async mounted() {
    this.getInsurancesTypeDelivery();
    await this.getDetail();
    await AccountController.checkAccountLocation();
    await this.fetchProductConfigurableRule();
    this.fetchDestinationCity("");
    this.getCommodityList();
    this.filterProductsDelivery("");
    this.setVendorNinja();
    this.processErrorEstimationPrice(
      this.manualBooking.sttGoodsEstimatePrice,
      this.manualBooking.sttProductTypeName === "OTOPACK150" ||
        this.manualBooking.sttProductTypeName === "OTOPACK250"
    );
    this.mountedProductUpsellingDefault();
    ShipmentBookingController.setWeightAttachFileInitialEdit();
    HeavyWeightSurchargeController.setMinimumHeavyWeightSurcharge({
      value: 0,
      existMinimum: false,
    });
    this.showWarningHws = false;
    await this.getConfigHws(
      this.manualBooking.sttProductTypeName,
      this.manualBooking.sttCommodityCode
    );
    this.onSetErrorHws();
    storeDatatoLocalStorage(
      "list-kg",
      ShipmentBookingController.shipmentBookingDetail.SttPieces
    );
    this.onCheckDisableWeightAdjustment();
    this.totalChargeableWeightKeeped = this.isCampaign
      ? this.totalCampaignChargeableWeight
      : this.totalChargeableWeight;
    this.loadPostalCode(this.manualBooking.sttDestinationAddress.id, true);
  }

  created() {
    window.addEventListener("keydown", this.onEnterBooking);
  }

  unmounted() {
    ShipmentBookingController.resetDetail();
    window.removeEventListener("keydown", this.onEnterBooking);
  }

  // exist customer
  get existCustomerSender(): any {
    return BookingController.existSender;
  }

  get existCustomerReceiver(): any {
    return BookingController.existReceiver;
  }

  // check input focused
  focusedText = false;

  checkFocus(value = "") {
    this.focusedText = true;
    if (this.isErrorEmbargo) {
      this.updateStatusErrorEmbargo(false);
    }

    this.isErrorMaxChWeightDimension =
      typeof value === "object" && this.isShipmentMp;
    this.isErrorMaxChWeightGoods =
      typeof value === "string" && this.isShipmentMp;
  }

  get defaultDimension() {
    return ShipmentBookingController.shipmentBookingDetail.defaultDimensions;
  }

  get disabledInput() {
    return this.manualBooking.sttColumnDisabled;
  }

  get manualBooking(): any {
    return ShipmentBookingController.shipmentBookingDetail;
  }

  get isLastStatusProofOfDelivery() {
    return this.manualBooking.sttLastStatus.toLowerCase() === "pod";
  }

  get disableStatusProofOfDelivery() {
    return (
      (this.formType === "client" &&
        this.getAccount.accountType !== "internal") ||
      !this.isLastStatusProofOfDelivery
    );
  }

  get id(): number {
    return Number(this.$route.params.id);
  }

  get isAuth(): any {
    return this.$route.meta.isAuth;
  }

  async getDetail() {
    await ShipmentBookingController.getEditBookingDetail({
      id: this.id,
      isAuth: this.isAuth,
    });
  }

  get isLoadingDetail() {
    return ShipmentBookingController.isLoadingDetail;
  }

  // weight & dimension
  get listKGs() {
    return ShipmentBookingController.shipmentBookingDetail.SttPieces;
  }

  isMaxLengthGrossWeight: any = null;

  formatFloat(value: string) {
    const regex = value
      .replace(/[^0-9.,]/, "")
      .replace(/\./g, ",")
      .replace(/,,/g, ",")
      .replace(/,\./g, ",")
      .replace(/,(\d+),/g, ",$1");

    this.isMaxLengthGrossWeight = maxLengthWeight(regex) + 1;
    return regex;
  }

  isCountGrossWeigth = false;

  validateGrossWeightPerKoli(value: number): boolean {
    if (value > maxKg) {
      this.isCountGrossWeigth = true;
      return true;
    }
    this.isCountGrossWeigth = false;
    return false;
  }

  calculationVolumeWeight(index: number) {
    const volume = volumeWeightForProduct(
      this.manualBooking.sttProductTypeName
    );
    const dividerVolumeByProduct = volume;

    this.listKGs[index].volumeWeight =
      (this.listKGs[index].dimension.length *
        this.listKGs[index].dimension.width *
        this.listKGs[index].dimension.height) /
      dividerVolumeByProduct;

    const split = this.listKGs[index].volumeWeight
      .toString()
      .replace(".", ",")
      .split(",");
    const front = split[0];
    const behind = split[1] ? "," + split[1].slice(0, 3) : "";
    this.listKGs[index].convertVolumeWeight = front + behind;
  }

  get validateTotalGrossWeight(): boolean {
    const total = Number(this.totalGrossWeight.replace(",", "."));
    return total > maxKg;
  }

  get validateTotalVolumeWeight(): boolean {
    let total = 0;
    this.listKGs.forEach((e: any) => (total += Number(e.volumeWeight)));
    return total > maxKg;
  }

  get arrGrossWeight() {
    let res = [];
    res = this.listKGs.map((key: any) => {
      return Number(key.grossWeight.toString().replace(/,/g, "."));
    });
    return res;
  }

  get totalGrossWeight() {
    return this.arrGrossWeight
      .reduce((a, b) => {
        return a + b;
      }, 0)
      .toLocaleString("id");
  }

  get arrVolumeWeight() {
    let res = [];
    res = this.listKGs.map((key: any) => {
      key.volumeWeight = Number(key.volumeWeight.toString().replace(/,/g, "."));
      return key.volumeWeight;
    });
    return res;
  }

  get totalVolumeWeight() {
    return this.arrVolumeWeight
      .reduce((a, b) => {
        return a + b;
      }, 0)
      .toLocaleString("id");
  }

  volumeWeight(value: any) {
    const split = value
      .toString()
      .replace(".", ",")
      .split(",");
    const front = split[0];
    const behind = split[1] ? "," + split[1].slice(0, 3) : "";
    return front + behind;
  }

  get productConfigRuleActive() {
    const isRetail = !this.manualBooking.sttClient.clientId;
    const clientId = this.isReverseJourneyPrefix
      ? this.manualBooking.sttBookedForId
      : this.manualBooking.sttClient.clientId;

    return this.productConfigurationRuleList.findProductConfigRule(
      this.manualBooking.sttProductTypeName,
      clientId,
      isRetail
    );
  }

  get isCampaign() {
    return this.productConfigRuleActive.isCampaign;
  }

  get isGrossWeight() {
    const x = Number(this.totalGrossWeight.replace(",", "."));
    const y = Number(this.totalVolumeWeight.replace(",", "."));
    return x > y;
  }

  get isVolumeWeight() {
    const x = Number(this.totalGrossWeight.replace(",", "."));
    const y = Number(this.totalVolumeWeight.replace(",", "."));
    return x < y;
  }

  get totalCampaignChargeableWeight() {
    let total: any = 0;
    const x = Number(this.totalGrossWeight.replace(",", "."));
    const y = Number(this.totalVolumeWeight.replace(",", "."));
    if (this.totalGrossWeight || this.totalVolumeWeight) {
      total = totalGrossWeightSikat(
        this.isCampaign,
        x,
        y,
        this.totalGrossWeight,
        this.totalVolumeWeight
      );
    } else {
      total = totalGrossWeightNonSikat(this.isCampaign, x, y);
    }
    return total;
  }

  get totalChargeableWeight() {
    let total: any = 0;
    const x = Number(this.totalGrossWeight.replace(",", "."));
    const y = Number(this.totalVolumeWeight.replace(",", "."));
    if (
      this.totalGrossWeight.includes(",") ||
      this.totalVolumeWeight.includes(",")
    ) {
      total = this.compareTotalGrossAndVolume(x, y);
    } else {
      total = `${x > y ? x : y}`;
    }
    return total;
  }

  validateEditWeightLoss(type: string) {
    if (!AccountController.accountData.isPosAccount) return false;
    if (this.validatePrefixs) return false;

    const currentChargeableWeight = this.isCampaign
      ? this.totalCampaignChargeableWeight
      : this.totalChargeableWeight;
    const isCurrentChargeableBelowFromPreviousChargeableWeight =
      currentChargeableWeight < this.manualBooking.sttTotalChargeAbleWeight;

    const grossWeight = Number(this.totalGrossWeight.replace(",", "."));
    const totalGrossWeight = Number(
      this.totalGrossWeightForValidation.replace(",", ".")
    );
    if (type === "grossWeight") {
      return grossWeight < totalGrossWeight;
    }

    const volumeWeight = Number(this.totalVolumeWeight.replace(",", "."));
    const totalVolumeWeight = Number(
      this.totalVolumeWeightForValidation.replace(",", ".")
    );
    if (type === "volumeWeight") {
      return (
        isCurrentChargeableBelowFromPreviousChargeableWeight &&
        volumeWeight < totalVolumeWeight
      );
    }
    return isCurrentChargeableBelowFromPreviousChargeableWeight;
  }

  validateEditOverWeightMp() {
    const currentChargeableWeight = this.isCampaign
      ? this.totalCampaignChargeableWeight
      : this.totalChargeableWeight;
    if (this.isShipmentMp) return currentChargeableWeight >= 5;
    return false;
  }

  get validatePrefixs() {
    const shipmentId = this.manualBooking.sttShipmentId;

    return (
      this.validatePrefixManual &&
      this.validatePrefixFavorite &&
      !shipmentId.startsWith("AG") &&
      !shipmentId.startsWith("AD") &&
      !shipmentId.startsWith("AO") &&
      !shipmentId.startsWith("ACA") &&
      !shipmentId.startsWith("ACB") &&
      !shipmentId.startsWith("AI")
    );
  }

  get validatePrefixManual() {
    const sttNo = this.manualBooking.sttNo;
    return !sttNo.startsWith("11") && !sttNo.startsWith("10");
  }

  get validatePrefixFavorite() {
    const shipmentId = this.manualBooking.sttShipmentId;
    return !shipmentId.startsWith("AP") && !shipmentId.startsWith("AS");
  }
  get errorMaxChWeight() {
    const currentChargeableWeight = this.isCampaign
      ? this.totalCampaignChargeableWeight
      : this.totalChargeableWeight;

    return gapValidationCHWeightTokped(
      this.totalChargeableWeightKeeped,
      currentChargeableWeight
    );
  }
  isErrorMaxChWeightDimension = false;
  isErrorMaxChWeightGoods = false;
  get errorAfterEditChWeight(): boolean {
    return (
      this.errorMaxChWeight &&
      (this.isErrorMaxChWeightGoods || this.isErrorMaxChWeightDimension)
    );
  }
  get errorMessageChWeight(): boolean {
    const first =
      this.onShowSectionAdjustmentWeight(
        this.manualBooking.sttShipmentId,
        this.manualBooking.sttNo
      ) &&
      this.isShipmentMp &&
      this.errorMaxChWeight;

    return first || this.errorAfterEditChWeight;
  }
  get disabledButtonErrorTokpedWeight() {
    return (
      this.errorMaxChWeight &&
      (this.isErrorMaxChWeightDimension || this.isErrorMaxChWeightGoods)
    );
  }
  get isShipmentMp() {
    const shipmentId =
      this.manualBooking.sttShipmentId || this.manualBooking.sttNoRefExternal;
    return (
      shipmentId.startsWith("T1") ||
      shipmentId.startsWith("TKLP") ||
      shipmentId.startsWith("TKP01")
    );
  }
  totalChargeableWeightKeeped = 0;

  compareTotalGrossAndVolume(x: number, y: number) {
    let total = 0;
    if (x > y) {
      total = this.grossMoreThanVolume(x);
    } else {
      total = this.grossLessThanVolume(y);
    }
    return total;
  }

  get enabledCommodityT1B1B2TKP01(){
    const prefix = this.manualBooking.sttShipmentId || this.manualBooking.sttNoRefExternal;
    return (prefix.startsWith("T1") || prefix.startsWith("B1") || prefix.startsWith("B2") || prefix.startsWith("TKP01"));
  }

  grossMoreThanVolume(x: number) {
    let total = 0;
    const split = this.totalGrossWeight.split(",");
    let result = x - Number(split[0]);
    result = Number(Number.parseFloat(result.toString()).toFixed(2));

    if (x < 1) {
      total = 1;
    } else {
      total =
        result >= this.productConfigRuleActive.parameter
          ? Math.ceil(x)
          : Math.floor(x);
    }

    return total;
  }

  grossLessThanVolume(y: number) {
    let total = 0;
    const split = this.totalVolumeWeight.split(",");
    let result = y - Number(split[0]);
    result = Number(Number.parseFloat(result.toString()).toFixed(2));

    if (y < 1) {
      total = 1;
    } else {
      total =
        result >= this.productConfigRuleActive.parameter
          ? Math.ceil(y)
          : Math.floor(y);
    }

    return total;
  }

  get convertedArr() {
    /* eslint-disable @typescript-eslint/camelcase */
    let rebuildArr = [];
    rebuildArr = this.listKGs.map((key: any) => ({
      stt_piece_id: key.pieceId,
      stt_piece_length: key.dimension.length,
      stt_piece_width: key.dimension.width,
      stt_piece_height: key.dimension.height,
      stt_piece_gross_weight: parseFloat(
        Number(key.grossWeight.toString().replace(",", ".")).toFixed(3)
      ),
      stt_piece_volume_weight: parseFloat(Number(key.volumeWeight).toFixed(3)),
    }));
    return rebuildArr;
  }

  checkIntersection() {
    const result = true;
    for (const el of this.convertedArr) {
      if (
        this.piecesAreZero(
          el.stt_piece_gross_weight,
          el.stt_piece_width,
          el.stt_piece_length,
          el.stt_piece_height
        )
      ) {
        return false;
      }
    }
    return result;
  }

  piecesAreZero(weight: number, width: number, length: number, height: number) {
    return weight === 0 || width === 0 || length === 0 || height === 0;
  }

  // tax number
  formatted(value: string) {
    const newValue = value.replace(/[^0-9.-]/g, "");
    return newValue;
  }

  validateTaxNumber(value: string) {
    this.focusedText = true;
    if (value.length !== 0 && value.length < 20) {
      this.error.taxNumber = !/[0-9-.]+/.test(value);
      this.error.taxNumber = true;
    } else {
      this.error.taxNumber = false;
    }
  }

  formatEstimationPrice(value: string) {
    return this.isSttForeign
      ? this.formatRinggit(value)
      : this.formatRupiah(value);
  }

  formatRupiah(value: string) {
    return formatInputMoney(value.replace(/[\D]+/g, ""));
  }

  formatRinggit(value: any) {
    return formatPriceWithoutCurrency(Number(value), "MYR");
  }

  formatPiecePerPack(value: string) {
    return value.replace(/[\D]+/g, "").replace(/\D|^0+/g, "");
  }

  // address type types
  isOpenSelectAddressType = false;

  get listAddressType() {
    const defaultChoices: any = [
      {
        name: this.$t("bookingShipment.column.office"),
        value: "office",
      },
      {
        name: this.$t("bookingShipment.column.home"),
        value: "home",
      },
    ];

    return defaultChoices;
  }

  onOpenSelectAddressType() {
    this.isOpenSelectAddressType = true;
  }

  onCloseSelectAddressType() {
    this.isOpenSelectAddressType = false;
  }

  onSelectAddressType() {
    this.focusedText = true;
  }

  // insurance
  insurance: any = {
    "Insurance Free": "free",
    "Insurance Basic": "basic",
    "Insurance Premium": "premium",
    free: "free",
    basic: "basic",
    premium: "premium",
  };

  get getInsuranceName() {
    return this.insurance[this.manualBooking.sttInsuranceName];
  }

  set setInsuranceName(value: string) {
    this.insurance[value] = value;
  }

  get setInsuranceName() {
    return this.insurance;
  }

  isOpenSelectInsurance = false;

  onOpenSelectInsurance() {
    this.isOpenSelectInsurance = true;
  }

  onCloseSelectInsurance() {
    this.isOpenSelectInsurance = false;
  }

  onSelectStatusInsurance(name: string, value: string) {
    this.manualBooking.sttInsuranceName = value;
    this.setInsuranceName = value;
    this.focusedText = true;

    const insurance = this.listInsurance.find(
      (item: any) => item.value == value
    );
    this.error.insurance = insurance?.status === "Inactive";
  }

  // status good
  listStatusGoods = [
    { name: "Pilih status pengiriman yang akan dikirim", value: "" },
    { name: "Ecommerce", value: "ecommerce" },
    { name: "Produk lokal / Batam", value: "batam" },
    { name: "Eks luar Batam / transit", value: "transit" },
    { name: "Salah kirim / return", value: "return" },
    { name: "Personal effect", value: "personal-effect" },
  ];

  get listStatusGoodsForIndonesian() {
    return [
      { name: "Pilih status pengiriman yang akan dikirim", value: "" },
      { name: "Ecommerce", value: "ecommerce" },
      { name: "Produk lokal / Batam", value: "batam" },
      { name: "Eks luar Batam / transit", value: "transit" },
      { name: "Salah kirim / return", value: "return" },
      { name: "Personal effect", value: "personal-effect" },
    ];
  }

  get listStatusGoodsForEnglish() {
    return [
      { name: "Select shipment status", value: "" },
      { name: "Retail", value: "retail" },
      { name: "Diplomatic pouch", value: "diplomatic-pouch" },
      { name: "Personal effect", value: "personal-effect" },
      { name: "Marketplace", value: "ecommerce" },
    ];
  }

  isStatusGoods = false;

  onOpenSelect() {
    this.isStatusGoods = true;
  }

  onCloseSelect() {
    this.isStatusGoods = false;
  }

  onSelectStatus(key: string, value: string) {
    this.manualBooking.sttSenderStatus = value;
    this.focusedText = true;
    this.setFieldIndex(10);
  }

  // comodities
  getCommodityList() {
    CommodityController.getCommodityList(new RequestListCommodity());
  }

  commodityName = "";

  get commodities() {
    return CommodityController.commodities.data.map((item) => {
      return {
        id: item.commodity_id,
        name: !this.accountIsForeign
          ? item.commodity_name
          : item.commodity_name_en,
        code: item.commodity_code,
        status: item.commodity_status.toLowerCase(),
      };
    });
  }

  fetchCommodityList = debounce((search: string) => {
    CommodityController.getCommodityList(
      new RequestListCommodity({
        search: search,
      })
    );
  }, 250);

  // location tracked
  get origin() {
    const detail = LocationController.districtDetail;
    return {
      id: detail.id,
      name: `${detail.name}, ${detail.cityName}`,
      code: `${detail.code}`,
      cityCode: `${detail.cityCode}`,
    };
  }

  getDestination() {
    return LocationController.getDetailDistrictByDistrictCode({
      districtCode: this.manualBooking.sttDestinationDistrictId,
    });
  }

  get destination() {
    return LocationController.destinationDistrict;
  }

  // destination city
  destinations = [];
  fetchDestinationCity = debounce((search: string) => {
    LocationController.getDistrictList({
      search: search,
      status: "",
      page: 1,
      limit: 10,
      type:
        this.manualBooking.sttReturnCod === "Yes"
          ? "within-city,forward-area"
          : "",
      cache: true,
    });
  }, 250);

  get isLoadingDestinationCity() {
    return LocationController.isLoadingDistrict;
  }

  get destinationCityList() {
    return LocationController.districtData.districtData
      .filter((key) => key.status.toLowerCase() === "active")
      .map((e) => ({
        id: e.id,
        name: `${e.name}, ${e.cityName}`,
        code: e.code,
        cityCode: e.cityCode,
        status: e.status.toLowerCase(),
        type: e.type,
        vendorCode: e.vendorCode,
        isCod: e.isCod,
      }));
  }

  refs: any = "";

  detailDistrict = new DistrictData();

  setDestination(val: any) {
    LocationController.setDestinationDistrict(val);

    this.setVendorNinja();
    this.focusedText = true;

    if (this.isErrorEmbargo) {
      this.updateStatusErrorEmbargo(false);
    }
    this.manualBooking.postalCodeDestination = "";
    this.loadPostalCode(val);
    if (this.manualBooking.sttDestinationAddress) {
      this.loadUpsellingProduct();
    } else {
      this.resetProductUpselling();
    }
    this.callZipCodeForForeign();
  }

  async callZipCodeForForeign() {
    if (this.manualBooking.sttDestinationAddress && this.isSttForeign) {
      this.loadPostalCode();
    }
  }

  postalCodeDestinationOptions: any = [];
  setPostalCode(value: any) {
    this.postalCodeDestinationOptions = value;
  }

  loadUpsellingProduct() {
    if (!this.isDisabledProduct) {
      this.manualBooking.sttProductTypeName = "";
      const visiblePricePosterProduct = this.visiblePrice;
      const renderPoster: any = this.$refs.renderPoster;
      renderPoster?.getPosterProduct(
        "",
        this.payload.sttOriginDistrictId,
        this.payload.sttDestinationDistrictId,
        visiblePricePosterProduct,
        this.clientlientIdForProductUpselling.accountRefId,
        this.clientlientIdForProductUpselling.accountType
      );
    }
  }

  resetProductUpselling() {
    if (!this.isDisabledProduct) {
      const renderPoster: any = this.$refs.renderPoster;
      renderPoster?.resetAllProduct();
    }
  }

  async loadPostalCode(val = "", isMounted = false) {
    if (
      this.manualBooking.sttDestinationAddress
    ) {
      const responseDistrictData = await LocationController.getDetailDistrict({
        id: this.manualBooking.sttDestinationAddress.id,
        notSaveDetail: true,
      });
      if (!responseDistrictData.destinationListZipCode.length) {
        this.postalCodeDestinationOptions = [];
        return;
      }
      this.postalCodeDestinationOptions = responseDistrictData.originListZipCode.map(
        (item: any) => {
          return {
            id: item,
            name: item,
          };
        }
      );
      if (val) this.manualBooking.postalCodeDestination = this.postalCodeDestinationOptions.length && isMounted ?
        {
            id: this.postalCodeDestinationOptions[0].id,
            name: this.postalCodeDestinationOptions[0].name,
        } : ""
      ;
    }
  }

  get isValidPostalCodeDestination() {
    if (this.manualBooking.sttProductTypeName === "INTERPACK") {
      return typeof this.manualBooking.postalCodeDestination === "object"
        ? !!this.manualBooking.postalCodeDestination.id
        : !!this.manualBooking.postalCodeDestination;
    }
    return true;
  }

  setVendorNinja() {
    ShipmentBookingController.setVendorNinja(
      this.manualBooking.sttDestinationAddress
    );
  }

  get isVendorNinja() {
    return ShipmentBookingController.isVendorNinja;
  }

  get isVendorNinjaValid() {
    if (this.isVendorNinja) {
      return this.listKGs?.length === 1;
    }
    return true;
  }

  // product type
  filterProductsDelivery = debounce((search: string) => {
    BookingController.getProductsDelivery({
      search: search,
      page: 1,
      limit: 10,
      status: "active",
    });
  }, 250);

  get productsDelivery() {
    return BookingController.productsDelivery.data.map((e) => ({
      id: e.id,
      name: e.name,
      status: e.status,
    }));
  }

  get isLoadingProduct() {
    return BookingController.loadingProductsDelivery;
  }

  async onSelectProduct(obj: any) {
    if (obj) {
      if (obj.status === "ACTIVE") {
        this.manualBooking.sttProductTypeName = obj.name;
        this.focusedText = true;
        for (let i = 0; i < this.listKGs.length; i++) {
          this.calculationVolumeWeight(i);
        }
        this.loadPostalCode(this.manualBooking.postalCodeDestination?.id);
        this.resetPhoto();
      } else {
        this.error.product = true;
      }
      this.setStatusInsurance(obj.name, obj.status === "ACTIVE");
    }

    if (this.isErrorEmbargo) {
      this.updateStatusErrorEmbargo(false);
    }
    if (
      this.manualBooking.sttProductTypeName &&
      this.manualBooking.sttCommodityCode
    ) {
      await this.getConfigHws(
        this.manualBooking.sttProductTypeName,
        this.manualBooking.sttCommodityCode
      );
      this.onSetErrorHws();
    }
  }

  // sender & receiver
  get senders() {
    return this.getPhoneNumbers;
  }

  isSaveReceiver = false;

  get receivers() {
    return this.getPhoneNumbers;
  }

  get getPhoneNumbers() {
    const list = BookingController.customerList.data;
    if (list.length !== 0) {
      return BookingController.customerList.data.map((item) => {
        return {
          ...item,
          customerPhoneNumber: `${item.customerPhoneNumber}`,
        };
      });
    } else {
      return list;
    }
  }

  setNameCustomer(search: string, type: string) {
    this.focusedText = true;
    if (type === "senders") {
      ShipmentBookingController.setNameSenderToEdit(search); // set payload even if not choosing
    }
    if (type === "receivers") {
      ShipmentBookingController.setNameRecipientToEdit(search); // set payload even if not choosing
    }

    if (search.length >= 3) {
      BookingController.setIsLoadingCustomer(true);
      this.fetchCustomer(search);
    } else {
      return;
    }
  }

  setCustomer(search: string, type: string) {
    if (type === "senders") {
      ShipmentBookingController.setNameSenderToEdit(search); // set payload even if not choosing
    }
    if (type === "receivers") {
      ShipmentBookingController.setNameRecipientToEdit(search); // set payload even if not choosing
    }
  }

  fetchCustomer = debounce((search: string) => {
    BookingController.getCustomerList({ search: search, cache: true });
  }, 1000);

  get isLoadingCustomer() {
    return BookingController.isLoadingCustomer;
  }

  onChooseSenders(item: any) {
    item = {
      ...item,
      customerPhoneCode: codePhoneCountry(item.customerPhoneNumber).code,
      customerPhoneFlag: codePhoneCountry(item.customerPhoneNumber).flag,
      customerPhoneNumber: codePhoneCountry(item.customerPhoneNumber).number,
    };

    this.manualBooking.sttSenderName = item.customerName;
    this.manualBooking.sttSenderAddress = item.customerAddress;
    this.manualBooking.sttSenderPhoneCode = item.customerPhoneCode;
    this.manualBooking.sttSenderPhoneFlag = item.customerPhoneFlag;
    this.manualBooking.sttSenderPhone = item.customerPhoneNumber;

    this.error.phoneCharMinimalSender = item.customerPhoneNumber.length < 5;
    this.validatePhoneSender(item.customerPhoneNumber);
  }

  onChooseReceivers(item: any) {
    item = {
      ...item,
      customerPhoneCode: codePhoneCountry(item.customerPhoneNumber).code,
      customerPhoneFlag: codePhoneCountry(item.customerPhoneNumber).flag,
      customerPhoneNumber: codePhoneCountry(item.customerPhoneNumber).number,
    };

    this.manualBooking.sttRecipientName = item.customerName;
    this.manualBooking.sttRecipientAddress = item.customerAddress;
    this.manualBooking.sttRecipientPhoneCode = item.customerPhoneCode;
    this.manualBooking.sttRecipientPhoneFlag = item.customerPhoneFlag;
    this.manualBooking.sttRecipientPhone = item.customerPhoneNumber;

    this.error.phoneCharMinimalReceiver = item.customerPhoneNumber.length < 5;
    this.validatePhoneReceiver(item.customerPhoneNumber);
  }

  validatePhoneReceiver(value = "") {
    const phoneArray = value.split("");
    if (this.detectPhoneNumber(phoneArray) && phoneArray.length > 14) {
      const newPhone = value.slice(0, -1);
      ShipmentBookingController.setReceiverPhoneToEdit(newPhone);
    } else {
      ShipmentBookingController.setReceiverPhoneToEdit(value);
    }
    this.focusedText = true;
    this.error.phoneReceiver = !/^\d+$/.test(value);
  }

  validatePhoneSender(value = "") {
    const phoneArray = value.split("");
    if (this.detectPhoneNumber(phoneArray) && phoneArray.length > 14) {
      const newPhone = value.slice(0, -1);
      ShipmentBookingController.setSenderPhoneToEdit(newPhone);
    } else {
      ShipmentBookingController.setSenderPhoneToEdit(value);
    }
    this.focusedText = true;
    this.error.phoneSender = !/^\d+$/.test(value);
  }

  detectPhoneNumber(phoneArray: Array<string>) {
    return phoneArray.find(
      () => phoneArray[0] === "0" && phoneArray[1] === "8"
    );
  }

  // formater
  formatNumber(value: string) {
    return value.replace(/[\D]+/g, "");
  }

  get disableSaveSender() {
    return (
      !this.manualBooking.sttSenderName ||
      !this.manualBooking.sttSenderPhone ||
      !this.manualBooking.sttSenderAddress ||
      this.error.phoneSender
    );
  }

  get disableSaveReceiver() {
    return (
      !this.manualBooking.sttRecipientName ||
      !this.manualBooking.sttRecipientPhone ||
      !this.manualBooking.sttRecipientAddress ||
      this.error.phoneReceiver
    );
  }

  onSaveCustomer = debounce((event: any, type: string) => {
    if (!event.target.checked) {
      return;
    }
    if (type === "sender") {
      if (
        this.manualBooking.sttSenderName &&
        this.manualBooking.sttSenderPhone
      ) {
        BookingController.addCustomer({
          name:
            typeof this.manualBooking.sttSenderName === "object"
              ? this.manualBooking.sttSenderName.customerName
              : this.manualBooking.sttSenderName,
          phone: `${this.manualBooking.sttSenderPhoneCode.substring(1)}${
            this.manualBooking.sttSenderPhone
          }`,
          address: this.manualBooking.sttSenderAddress,
        });
      }
    } else if (type === "receiver") {
      if (
        this.manualBooking.sttRecipientName &&
        this.manualBooking.sttRecipientPhone
      ) {
        BookingController.addCustomer({
          name:
            typeof this.manualBooking.sttRecipientName === "object"
              ? this.manualBooking.sttRecipientName.customerName
              : this.manualBooking.sttRecipientName,
          phone: `${this.manualBooking.sttRecipientPhoneCode.substring(1)}${
            this.manualBooking.sttRecipientPhone
          }`,
          address: this.manualBooking.sttRecipientAddress,
        });
      }
    }
  }, 1000);

  // detail shipment
  get detailShipment() {
    return {
      destination: this.destination,
      isSaveReceiver: false,
    };
  }

  get isMixpack() {
    return AccountController.accountData.account_type === "client"
      ? AccountController.accountData.account_type_detail.isMixpack
      : false;
  }

  get payload(): any {
    return {
      ...this.payloadSectionOne,
      ...this.payloadSectionTwo,
      ...this.payloadSectionThree,
    };
  }

  get payloadSectionOne() {
    return {
      id: this.manualBooking.sttId,
      isMixpack: this.isMixpack,
      sttNoRefExternal: this.manualBooking.sttNoRefExternal,
      sttTaxNumber: this.manualBooking.sttTaxNumber,
      sttGoodsEstimatePrice: Number(
        this.manualBooking.sttGoodsEstimatePrice
          .replace(/[.]/g, "")
          .replaceAll(",", ".")
      ),
      sttGoodsStatus: this.manualBooking.sttSenderStatus,
      sttOriginCityId: this.manualBooking.sttOriginCityId,
      sttOriginDistrictId: this.manualBooking.sttOriginDistrictId,
      sttDestinationCityId:
        this.destination.cityCode === "" ||
        this.destination.cityCode === "undefined"
          ? this.manualBooking.sttDestinationCityId
          : this.destination.cityCode,
      sttDestinationDistrictId:
        this.destination.code === ""
          ? this.manualBooking.sttDestinationDistrictId
          : this.destination.code,
      sttSenderName:
        typeof this.manualBooking.sttSenderName === "object"
          ? this.manualBooking.sttSenderName.customerName
          : this.manualBooking.sttSenderName,
      sttSenderPhone: `${this.manualBooking.sttSenderPhoneCode.substring(1)}${
        this.manualBooking.sttSenderPhone
      }`,
    };
  }

  get payloadSectionTwo() {
    return {
      sttSenderAddress: this.manualBooking.sttSenderAddress,
      sttIsSaveSender: this.existCustomerSender.existSender,
      sttRecipientName:
        typeof this.manualBooking.sttRecipientName === "object"
          ? this.manualBooking.sttRecipientName.customerName
          : this.manualBooking.sttRecipientName,
      sttRecipientAddress: this.manualBooking.sttRecipientAddress,
      sttRecipientAddressType: this.manualBooking.sttRecipientAddressType,
      sttRecipientPhone: `${this.manualBooking.sttRecipientPhoneCode.substring(
        1
      )}${this.manualBooking.sttRecipientPhone}`,
      sttIsSaveRecipient: this.existCustomerReceiver.existReceiver,
      sttInsuranceType: this.getInsuranceName,
      sttProductType: this.manualBooking.sttProductTypeName,
      sttCommodityCode:
        typeof this.manualBooking.sttCommodityName === "object"
          ? this.manualBooking.sttCommodityName.code
          : this.manualBooking.sttCommodityCode,
      sttCommodityId:
        typeof this.manualBooking.sttCommodityName === "object"
          ? this.manualBooking.sttCommodityName.id
          : this.manualBooking.sttCommodityId,
      sttClientSttId: 0,
      sttVendorSttId: 0,
      sttIsCod: this.manualBooking.sttIsCod,
      sttIsDfod: this.manualBooking.sttIsDfod,
      sttRecipientEmail: this.manualBooking.sttRecipientEmail,
      sttKtpImage: this.manualBooking.sttKtpImage,
      sttTaxImage: this.manualBooking.sttTaxImage,
    };
  }

  get payloadSectionThree() {
    return {
      sttIsPad: this.manualBooking.sttIsPad,
      sttCodAmount: this.manualBooking.sttIsDfod
        ? 0
        : Number(
            this.manualBooking.sttCodAmount.toString().replace(/[.]/g, "")
          ),
      sttIsDo: this.manualBooking.sttReturnCod === "Yes",
      sttIsWoodpacking: this.manualBooking.sttIsWoodPacking,
      sttPieces: this.convertedArr,
      sttPiecePerPack: Number(this.manualBooking.sttPiecePerPack),
      sttNextCommodity: this.manualBooking.sttNextCommodity,
      sttBooked: {
        sttShipmentId: this.manualBooking.sttShipmentId,
        sttBookedById: this.manualBooking.sttBookedById,
        sttBookedByType: this.manualBooking.sttBookedByType,
        sttBookedByCode: this.manualBooking.sttBookedByCode,
        sttBookedForId: this.manualBooking.sttBookedForId,
        sttBookedForType: this.manualBooking.sttBookedForType,
        sttBookedForCode: this.manualBooking.sttBookedForCode,
      },
      sttShipmentId: this.manualBooking.sttShipmentId,
      postalCodeDestination:
        typeof this.manualBooking.postalCodeDestination === "object"
          ? this.manualBooking.postalCodeDestination?.id
          : this.manualBooking.postalCodeDestination,
      sttLastStatus: this.manualBooking.sttLastStatus,
      sttNo: this.manualBooking.sttNo,
      sttGrossWeight: this.totalGrossWeight,
      sttVolumeWeight: Number(
        this.totalVolumeWeight.toString().replace(",", ".")
      ),
      sttChargeableWeight: this.isCampaign
        ? Number(this.totalCampaignChargeableWeight)
        : Number(this.totalChargeableWeight.toString().replace(",", ".")),
      shipmentC1Cod: this.shipmentC1Cod,
      clientIdBranch: this.manualBooking.sttClient.clientId,
      promoPrefix: this.promoPrefix,
      codHandling: this.shipmentC1Cod ? this.manualBooking.codHandling : "",
      sttWeightAttachFiles: this.manualBooking.sttWeightAttachFiles.filter(
        (item: string) => item
      ),
      sttAttachFiles: this.manualBooking.sttAttachFiles.filter(
        (item: string) => item
      ),
      sttCommodityDetail: this.manualBooking.sttCommodityDetail,
      shipmentReverseJourney: this.manualBooking.sttReverseJourneyShipmentId,
      discountFavoritePercentage: this.manualBooking.discountFavoritePercentage,
    };
  }

  get isFormValid() {
    return !!(
      this.focusedText &&
      this.formValidationShipmentDetail &&
      this.formValidationShipmentGood &&
      this.isFormValidationError &&
      this.isFormValidationWeightVolume &&
      this.isFormValidationOther &&
      this.manualBooking.postalCodeDestination?.id
    );
  }

  get formValidationShipmentDetail() {
    return (
      this.payload.sttDestinationCityId &&
      this.payload.sttDestinationDistrictId &&
      this.payload.sttSenderPhone &&
      this.payload.sttRecipientPhone &&
      this.payload.sttSenderName &&
      this.payload.sttRecipientName &&
      this.payload.sttSenderAddress &&
      this.payload.sttRecipientAddress
    );
  }

  get formValidationShipmentGood() {
    const isDestinationActive = this.manualBooking.sttDestinationAddress;
    const isCommodityActive = this.manualBooking.sttCommodityName;

    return (
      this.payload.sttCommodityCode &&
      this.payload.sttInsuranceType &&
      this.checkIntersection() &&
      this.payload.sttProductType &&
      this.payload.sttRecipientAddressType &&
      isDestinationActive &&
      isCommodityActive &&
      !this.isShipperAndConsigneeAddressIdentical
    );
  }

  get isFormValidationError() {
    return (
      !this.error.maxTotalGrossWeight &&
      !this.error.maxPerPieces &&
      !this.error.product &&
      !this.error.insurance &&
      !this.error.taxNumber &&
      !this.error.phoneCodeSender &&
      !this.error.phoneCodeReceiver &&
      !this.error.phoneCharMinimalSender
    );
  }

  get isFormValidationWeightVolume() {
    return (
      !this.validateTotalGrossWeight &&
      !this.validateTotalVolumeWeight &&
      !this.validateEditWeightLoss("grossWeight") &&
      !this.validateEditWeightLoss("volumeWeight")
    );
  }

  get isFormValidationOther() {
    return (
      this.isFtzRequiredForm &&
      this.isClientRequiredForm &&
      this.isEstimationGoodMandatory &&
      this.isVendorNinjaValid &&
      !this.error.phoneCharMinimalReceiver &&
      this.isValidPostalCodeDestination &&
      !this.errorEstimationPrice &&
      !this.errorAfterEditChWeight
    );
  }

  // pos for client edit
  get formType() {
    if (
      this.manualBooking.sttClient?.clientName &&
      this.manualBooking.sttClient?.clientName !== "Customer Apps"
    ) {
      return "client";
    }

    return this.conditionFormType();
  }

  conditionFormType() {
    return this.manualBooking.sttClient?.clientName &&
      this.manualBooking.sttClient?.clientName === "Customer Apps"
      ? "marketplace"
      : "manual";
  }

  async getInsurancesTypeDelivery() {
    await BookingController.getInsuranceTypesDelivery({
      insuranceConfig: 2,
    });
  }

  disableAsuranceOtoPack = false;
  setStatusInsurance(productName: string, productStatus: boolean) {
    const product =
      (productName === "OTOPACK150" || productName === "OTOPACK250") &&
      productStatus;
    if (product) {
      this.manualBooking.sttInsuranceName = "basic";
      this.disableAsuranceOtoPack = true;
      this.processErrorEstimationPrice(
        this.manualBooking.sttGoodsEstimatePrice,
        true
      );
    } else {
      this.manualBooking.sttInsuranceName = this.insurance[
        this.manualBooking.sttInsuranceNameDefault
      ];
      this.disableAsuranceOtoPack = false;
      this.errorEstimationPrice = false;
    }
  }

  errorEstimationPrice = false;
  processErrorEstimationPrice(price: string, isOto: boolean) {
    const estimasi = Number(price.replace(/\./g, "").replaceAll(",", "."));
    if (
      this.otoAndEstimationMoreThanZero(isOto, estimasi) &&
      estimasi < 5000000
    ) {
      this.errorEstimationPrice = true;
    } else {
      this.errorEstimationPrice = false;
    }
  }

  otoAndEstimationMoreThanZero(isOto: boolean, estimasi: number) {
    return isOto && estimasi > 0;
  }

  get insuranceDisable() {
    return (
      this.isBookingShipment ||
      (this.formType === "client" &&
        this.getAccount.accountType !== "internal") ||
      !this.isLastStatusProofOfDelivery
    );
  }

  get listInsurance() {
    const defaultChoices: any = [
      {
        name: "Pilih Asuransi",
        value: "",
        status: "",
      },
    ];

    const insurance = BookingController.allInsuranceTypeDelivery.data.map(
      (e: any) => ({
        name: e.name,
        value: e.value,
        status: e.status,
      })
    );

    return defaultChoices.concat(insurance);
  }

  get errorPhoneShipper() {
    let phoneShipper = "";
    if (BookingController.errorPhoneNumber) {
      if (
        BookingController.errorPhoneNumber.toLowerCase().includes("pengirim")
      ) {
        phoneShipper = BookingController.errorPhoneNumber;
      }
    }

    return phoneShipper;
  }

  get errorPhoneConsignee() {
    let phoneConsignee = "";
    if (BookingController.errorPhoneNumber) {
      if (
        BookingController.errorPhoneNumber.toLowerCase().includes("penerima")
      ) {
        phoneConsignee = BookingController.errorPhoneNumber;
      }
    }

    return phoneConsignee;
  }

  get getAccount(): any {
    return {
      accountType: AccountController.accountData.account_type,
      cityCode: AccountController.accountData.account_type_detail
        .partnerLocation
        ? AccountController.accountData.account_type_detail.partnerLocation
            .city_code
        : "",
      accountClientId: AccountController.accountData.account_type_detail.id
        ? AccountController.accountData.account_type_detail.id
        : "",
    };
  }

  get bookingType() {
    if (
      this.manualBooking.sttShipmentId &&
      this.manualBooking.sttClient.clientId === 0
    ) {
      return "shipment";
    } else if (
      !this.manualBooking.sttShipmentId &&
      this.manualBooking.sttClient.clientId === 0
    ) {
      return "manual";
    } else {
      return "client";
    }
  }

  updateStatusErrorEmbargo(status: boolean) {
    this.errorEmbargo.originCity = status;
    this.errorEmbargo.destinationCity = status;
    this.errorEmbargo.productType = status;
    this.errorEmbargo.comodity = status;
    this.errorEmbargo.weightStatus = status;
    this.errorEmbargo.lengthStatus = status;
  }

  // error embargo
  get isErrorEmbargo() {
    return BookingController.isErrorEmbargo;
  }

  errorEmbargo = {
    originCity: false,
    destinationCity: false,
    productType: false,
    comodity: false,
    weightStatus: false,
    lengthStatus: false,
  };

  onUpdateEmbargoStatusFail() {
    if (this.isErrorEmbargo) {
      this.updateStatusErrorEmbargo(false);
    }
  }

  get isShipperAndConsigneeAddressIdentical() {
    if (
      this.manualBooking.sttSenderAddress === "" ||
      this.manualBooking.sttRecipientAddress === ""
    ) {
      return false;
    }
    return (
      this.manualBooking.sttSenderAddress?.toLowerCase() ===
      this.manualBooking.sttRecipientAddress?.toLowerCase()
    );
  }

  get isFtzRequiredForm() {
    if (this.isFtzAccount && !this.isSttForeign) {
      return !!(
        this.manualBooking.sttSenderStatus &&
        this.manualBooking.sttPiecePerPack &&
        this.manualBooking.sttNextCommodity
      );
    }

    if (this.isFtzAccount && this.isSttForeign) {
      return !!this.manualBooking.sttTaxNumber;
    }

    return true;
  }

  get isFtzAccount() {
    return (
      AccountController.accountData.account_location?.city_free_trade_zone ===
        "yes" ||
      this.manualBooking.sttPiecePerPack ||
      this.manualBooking.sttNextCommodity
    );
  }

  get isClientRequiredForm() {
    if (this.formType === "client") {
      return !!this.manualBooking.sttSenderStatus;
    }
    return true;
  }

  get isBookingShipment() {
    if (
      this.manualBooking.sttDestinationAddress === "" ||
      prefixAlgoEstimationShow(this.manualBooking.sttShipmentId)
    ) {
      return /^ARA|^ARB/.test(this.manualBooking.sttShipmentId);
    } else {
      return (
        !!this.manualBooking.sttShipmentId ||
        (this.formType === "client" &&
          this.manualBooking.sttIsCod &&
          this.getAccount.accountType !== "internal")
      );
    }
  }

  get isEstimationGoodMandatory() {
    if (this.getInsuranceName === "free") {
      return (
        this.payload.sttGoodsEstimatePrice >= 0 ||
        !this.payload.sttGoodsEstimatePrice
      );
    } else {
      return !(
        this.payload.sttGoodsEstimatePrice <= 0 ||
        !this.payload.sttGoodsEstimatePrice
      );
    }
  }

  prefixAlgoEstimationShow(algoId: string) {
    return prefixAlgoEstimationShow(algoId);
  }

  // error indicator mandatory
  currentFieldIndex = 0;

  setFieldIndex(index: number) {
    if (index > this.currentFieldIndex) {
      this.currentFieldIndex = index;
    }
  }

  isWeightFullFilled = false;

  validateFullFilledWeight(value: number): boolean {
    if (!value) {
      this.isWeightFullFilled = true;
      return true;
    }
    this.isWeightFullFilled = false;
    return false;
  }

  isVolumeFullFilled = false;

  validateFullFilledVolume(value: number): boolean {
    if (value === 0) {
      this.isVolumeFullFilled = true;
      return true;
    }
    this.isVolumeFullFilled = false;
    return false;
  }

  get errorMandatory() {
    return [
      !this.payload.sttProductType,
      !this.payload.sttSenderName,
      !this.payload.sttRecipientName,
      !this.payload.sttSenderPhone,
      !this.payload.sttRecipientPhone,
      !this.payload.sttSenderAddress,
      !this.payload.sttRecipientAddress,
      this.errorMandatoryDestinationColumn,
      false,
      this.errorMandatoryCommodityColumn,
      this.errorMandatoryEstimationPriceColumn,
      false,
    ];
  }

  get errorMandatoryDestinationColumn() {
    return (
      !this.payload.sttDestinationDistrictId ||
      this.payload.sttDestinationDistrictId === "undefined"
    );
  }

  get errorMandatoryEstimationPriceColumn() {
    return (
      !this.payload.sttGoodsEstimatePrice && this.getInsuranceName !== "free"
    );
  }

  get errorMandatoryCommodityColumn() {
    return !this.manualBooking.sttCommodityName;
  }

  get errorMandatoryDetailBarang() {
    return [
      this.errorMandatoryGoodStatusColumn,
      !this.payload.sttPiecePerPack && this.isFtzAccount,
      !this.payload.sttNextCommodity && this.isFtzAccount,
      !this.manualBooking.sttCommodityDetail && this.isProductInterpack,
      this.errorMandatoryFileAttachmentProduct,
    ];
  }

  get errorMandatoryGoodStatusColumn() {
    return (
      !this.payload.sttGoodsStatus &&
      (this.isFtzAccount || this.formType === "client") &&
      this.currentFieldIndex > 12
    );
  }

  get errorMandatoryFileAttachmentProduct() {
    return (
      this.manualBooking.sttAttachFiles.length < 3 && this.isProductInterpack
    );
  }

  validateNextCommodity(value: string) {
    this.checkFocus();
    this.error.nextCommodity = this.isFtzAccount && value.length <= 0;
  }

  maxLengthPhoneNumber(phone: string) {
    return maxLengthPhoneNumber(phone);
  }

  get urlVersion() {
    return isUrlUpdateVersion("customer");
  }

  isSubmit = false;

  onEnterBooking(event: any) {
    this.isSubmit = false;
    if (event.key === "Enter") {
      if (this.isDisabledButton) {
        this.setFieldIndex(14);
        return;
      }
      if (this.conditionSubmitAndSuccess && !MainAppController.loadingModal) {
        this.booking();
        return;
      }
    }
  }

  get conditionSubmitAndSuccess() {
    return !this.isSubmit && !this.openSuccess;
  }

  get isDisabledButton() {
    return !this.isFormValid || this.isShipperAndConsigneeAddressIdentical;
  }

  replaceNewLine(value: string) {
    return removeNewLineWhitespace(value, " ");
  }

  // sender
  get errorPhoneSender() {
    return {
      errorPhone: this.error.phoneSender,
      errorMandatory: this.errorMandatory[3],
      errorPhoneLabel: this.errorPhoneShipper,
      errorPhoneCode: this.error.phoneCodeSender,
      phoneCharMinimal: this.error.phoneCharMinimalSender,
    };
  }

  setPhoneSender(val: string) {
    ShipmentBookingController.setSenderPhoneToEdit(val);
  }

  setPhoneCodeSender(val: string) {
    ShipmentBookingController.setSenderPhoneCodeToEdit(val);
  }

  setPhoneFlagSender(val: string) {
    ShipmentBookingController.setSenderPhoneFlagToEdit(val);
  }

  setErrorPhoneSender(val: any) {
    this.error.phoneSender = !/^\d+$/.test(val);
  }

  setErrorCharMinimalSender(status: boolean) {
    this.error.phoneCharMinimalSender = status;
  }

  setErrorPhoneCodeSender(status: boolean) {
    this.error.phoneCodeSender = status;
  }

  // receiver
  get errorPhoneReceiver() {
    return {
      errorPhone: this.error.phoneReceiver,
      errorMandatory: this.errorMandatory[4],
      errorPhoneLabel: this.errorPhoneConsignee,
      errorPhoneCode: this.error.phoneCodeReceiver,
      phoneCharMinimal: this.error.phoneCharMinimalReceiver,
    };
  }

  setErrorPhoneReceiver(val: any) {
    this.error.phoneReceiver = !/^\d+$/.test(val);
  }

  setErrorPhoneCodeReceiver(status: boolean) {
    this.error.phoneCodeReceiver = status;
  }

  setErrorCharMinimalReceiver(status: boolean) {
    this.error.phoneCharMinimalReceiver = status;
  }

  setPhoneReceiver(val: string) {
    ShipmentBookingController.setReceiverPhoneToEdit(val);
  }

  setPhoneCodeReceiver(val: string) {
    ShipmentBookingController.setReceiverPhoneCodeToEdit(val);
  }

  setPhoneFlagReceiver(val: string) {
    ShipmentBookingController.setReceiverPhoneFlagToEdit(val);
  }

  get enableForInternalPod() {
    return (
      this.formType === "client" &&
      this.getAccount.accountType === "internal" &&
      this.manualBooking.sttLastStatus.toLowerCase() === "pod"
    );
  }

  get disableCod() {
    if (this.enableForInternalPod && this.manualBooking.sttIsCod) {
      return false;
    } else {
      return !this.enableForInternalPod && this.manualBooking.sttIsCod;
    }
  }

  get isSttRetail() {
    return !!(
      this.manualBooking.sttNo.match(/^11LP/gi) ||
      this.manualBooking.sttNo.match(/^10LP/gi)
    );
  }

  get isProductInterpack() {
    return this.manualBooking.sttProductTypeName === "INTERPACK";
  }

  get IS_RTS() {
    return (
      AccountController.accountData.isRtsFeatureAvailable &&
      ShipmentBookingController.shipmentBookingDetail.lastStatusSttReturn ===
        "RTS"
    );
  }

  get IS_RTSHQ() {
    return (
      AccountController.accountData.isRtsFeatureAvailable &&
      ShipmentBookingController.shipmentBookingDetail.lastStatusSttReturn ===
        "RTSHQ"
    );
  }

  get isInternal(): boolean {
    return this.getAccount.accountType === "internal";
  }

  get isReverseJourneyPrefix(): boolean {
    return !!this.manualBooking.lastStatusSttReturn.match(/^REROUTE|^RTS/gi);
  }

  get isReverseJourneyEditableReceiver(): boolean {
    if (this.isInternal) {
      return !!this.manualBooking.sttNo.match(/^94|^89|^78|^77|^76|^66/gi);
    }
    return (
      !!this.manualBooking.lastStatusSttReturn.match(/^REROUTE|^RTS$/gi) &&
      !this.IS_RTSHQ
    );
  }

  get disableDeliverySenderAndReceiverInternalPupOnWards() {
    if (this.isInternal || AccountController.accountData.isConsoleAccount) {
      return !(this.manualBooking.sttLastStatus === 'BKD' || this.manualBooking.sttLastStatus.includes("STT ADJUSTED"))
    }
    return false;
  }
  get isDisableDeliverySender(): boolean {
    this.disableLastStatusPod();
    return (
      this.disableDeliverySenderAndReceiverInternalPupOnWards ||
      this.isReverseJourneyEditableReceiver ||
      this.isBookingShipment && (this.manualBooking.sttLastStatus !== 'STT ADJUSTED' && this.manualBooking.sttLastStatus !== 'BKD') ||
      this.IS_RTSHQ
    );
  }

  get isDisableDeliveryReceiver(): boolean {
    this.disableLastStatusPod();
    if (this.isReverseJourneyEditableReceiver || this.manualBooking.sttLastStatus === "STT ADJUSTED" || this.manualBooking.sttLastStatus === "BKD") {
      return false;
    }
    return (
      this.disableDeliverySenderAndReceiverInternalPupOnWards ||
      this.disableCod || this.isBookingShipment || this.IS_RTSHQ
    );
  }

  get isDisablePieceDetail(): boolean {
    return !this.isInternal && this.isReverseJourneyPrefix;
  }

  // configurable rule product
  productConfigurationRuleList: ProductConfigurationRuleList = new ProductConfigurationRuleList();

  async fetchProductConfigurableRule() {
    try {
      this.productConfigurationRuleList = await ProductController.fetchProductConfigurationRule(
        new ProductConfigurationRuleRequestParams()
      );
    } catch (err) {
      this.productConfigurationRuleList = new ProductConfigurationRuleList();
    }
  }

  setPostalCodeDestination(value: string) {
    if (value) {
      this.manualBooking.postalCodeDestination = {
        id: value,
        name: value,
      };
      return;
    }
    this.manualBooking.postalCodeDestination = "";
  }

  get eligibleSttToEditOnPOD() {
    return (
      this.manualBooking.sttShipmentId.match(/^CCR|^B1/gi) ||
      (this.manualBooking.sttNo.match(
        /^77LP|^78LP|^94LP|^66LP|^89LP|^95LP|^99LP|^98LP|^19LP|^88LP/gi
      ) &&
        this.manualBooking.sttClient.clientId > 0)
    );
  }

  get isDisabledProduct() {
    this.disableLastStatusPod();
    if (this.isReverseJourneyPrefix) {
      return false;
    }

    if (this.isSttSTI) {
      return false;
    }

    return this.isBookingShipment || this.disableStatusProofOfDelivery;
  }

  get isSttSTI() {
    return (
      this.manualBooking.sttNo.match(/^77LP|^78LP|^94LP|^66LP|^89LP/gi) &&
      this.manualBooking.sttLastStatus.toLowerCase() === "sti" &&
      AccountController.accountData.isConsoleAccount
    );
  }

  get isDisabledStatusByPod() {
    this.disableLastStatusPod();
    if (this.isReverseJourneyPrefix) {
      return false;
    }
    return this.isBookingShipment;
  }

  get isDisabledInsuranceField() {
    if (this.isLastStatusProofOfDelivery) {
      return !this.eligibleSttToEditOnPOD || this.insuranceDisable;
    }
    return this.customPath === "on-process-stt" || this.insuranceDisable;
  }

  disableLastStatusPod() {
    if (this.isLastStatusProofOfDelivery) {
      return !this.eligibleSttToEditOnPOD;
    }
  }

  get isDisabledGoodsPriceEstimation() {
    return true;
  }

  formatPrice(value: number) {
    return !AccountController.accountData.accountIsForeign
      ? formatPriceRP(value)
      : formatPriceRM(value);
  }

  discount: Diskon = {
    isDiscount: false,
    totalTariff: 0,
    totalAfterDiscount: 0,
    totalBeforeDiscount: 0,
  };

  setDiscount(params: Diskon) {
    this.discount = {
      isDiscount: params.isDiscount,
      totalTariff: params.totalTariff,
      totalAfterDiscount: params.totalAfterDiscount,
      totalBeforeDiscount: params.totalBeforeDiscount,
    };
  }

  get visiblePrice() {
    return (
      (this.bookingType === "manual" &&
        (this.getAccount.accountType === "partner" ||
          this.getAccount.accountType === "client")) ||
      prefixAlgoEstimationShow(this.manualBooking.sttShipmentId)
    );
  }

  get errorProduct() {
    if (!this.errorDestintaionProductUpselling) {
      if (this.errorMandatory[0]) {
        return this.$t("bookingShipment.error.serviceType");
      }

      if (this.errorEmbargo.productType) {
        return this.$t("bookingShipment.error.embargoStatus");
      }
    }

    return "";
  }

  errorDestintaionProductUpselling = false;
  setErrorDestintaionProductUpselling(status: boolean) {
    this.errorDestintaionProductUpselling = status;
    BookingController.setErrorDestination(status);
  }

  get errorDestinationCity() {
    return BookingController.errorDestination;
  }

  mountedProductUpsellingDefault() {
    const renderPoster: any = this.$refs.renderPoster;
    renderPoster?.setActiveProductDisabled();
  }

  get clientlientIdForProductUpselling() {
    let accountRefId = 0;
    let accountType = "";

    if (this.formType === "manual") {
      accountRefId = 0;
      accountType = "pos";
    } else {
      accountRefId = this.manualBooking.sttClient.clientId;
      accountType = "client";
    }

    return {
      accountRefId,
      accountType,
    };
  }

  get isOnePack() {
    return this.formType === "client";
  }

  get shipmentC1Cod() {
    return (
      this.manualBooking.sttShipmentId.startsWith(
        FlagsFeatureShipmentC1.feature_shipment_c1.getValue()
      ) && this.manualBooking.sttIsCod
    );
  }

  get promoPrefix() {
    return this.visiblePrice || this.shipmentC1Cod
      ? promoPrefix(this.manualBooking.sttShipmentId)
      : "";
  }

  get showCodSection() {
    return (
      !!this.manualBooking.sttClient.clientName &&
      !this.manualBooking.sttShipmentId
    );
  }

  updateCommodity(val: string) {
    this.checkFocus();
    this.manualBooking.sttCommodityDetail = val;
  }

  updateFiles(files: string[]) {
    this.checkFocus();
    this.manualBooking.sttAttachFiles = files;
  }

  tabKey = 0;
  resetPhoto() {
    this.manualBooking.sttAttachFiles = this.manualBooking.sttAttachFilesInitial;
    this.manualBooking.sttCommodityDetail = this.manualBooking.sttCommodityDetailInitial;
    this.tabKey++;
  }

  get disableDetailCommodity() {
    if (this.isProductInterpack) {
      return !this.manualBooking.sttCommodityDetail;
    }

    return false;
  }

  get disableProofCommodity() {
    if (this.isProductInterpack) {
      const newFiles = this.manualBooking.sttAttachFiles.filter(
        (item: string) => item
      );
      return newFiles.length < 3;
    }

    return false;
  }

  get eligibleAdditionalInfo() {
    return (
      FlagsMedusa.flag_release_interpack_additional_info.isEnabled() &&
      ((this.detailDistrict.countryCode &&
        this.detailDistrict.countryCode !== "ID") ||
        this.manualBooking.productName === "INTERPACK")
    );
  }

  additionalInfo: any = {
    commodity: "",
    email: "",
    files: [],
  };

  errAditionalInfo = {
    email: false,
    commodity: false,
    files: false,
  };

  get totalGrossWeightForValidation() {
    return this.manualBooking.sttPiecesGrossWeight
      .reduce((a: number, b: number) => {
        return a + b;
      }, 0)
      .toLocaleString("id");
  }

  get totalVolumeWeightForValidation() {
    return this.manualBooking.sttPiecesVolumeWeight
      .reduce((a: number, b: number) => {
        return a + b;
      }, 0)
      .toLocaleString("id");
  }

  get deliveryFeeType() {
    let result;
    if (!this.manualBooking.sttIsCod && !this.manualBooking.sttIsDfod) {
      result = "non-cod";
    }

    if (this.manualBooking.sttIsCod && !this.manualBooking.sttIsDfod) {
      result = "cod";
    }

    if (this.manualBooking.sttIsDfod) {
      result = "dfod";
    }
    return result;
  }
  get deliveryFeeTypes() {
    return [
      { name: "Non COD", value: "non-cod", disabled: true, logo: "" },
      {
        name: "Cash On Delivery",
        value: "cod",
        disabled: true,
        logo: "cod-logo",
      },
      {
        name: "Delivery Fee on Delivery",
        value: "dfod",
        disabled: true,
        logo: "dfod-logo",
      },
    ];
  }

  get isPartnerPcu() {
    return BookingController.dataProfile.isPartnerPcu;
  }

  get showJumboPack() {
    const showJumboPack =
      (this.manualBooking.sttProductTypeName.toLowerCase() === "jumbopack" ||
        this.manualBooking.sttProductTypeName.toLowerCase() ===
          "jumbopackh2h") &&
      this.accountAllowedAccessJumboPack;
    return showJumboPack ? true : false;
  }

  get showSameDay() {
    return (
      this.manualBooking.sttProductTypeName.toLowerCase() === "sameday" &&
      (this.manualBooking.sttShipmentId?.startsWith("AD") ||
        this.manualBooking.sttShipmentId?.startsWith("AG"))
    );
  }

  get accountAllowedAccessJumboPack() {
    const accPos =
      this.isPartnerPcu && AccountController.accountData.isPosAccount;
    const accInternal = this.isInternal;
    const accConsole = AccountController.accountData.isConsoleAccount;
    const accSubConsole = AccountController.accountData.isSubConsoleAccount;
    const accClient = AccountController.accountData.isAllClient;

    return accPos || accInternal || accConsole || accSubConsole || accClient;
  }

  get checkoutPaymentMethod() {
    return ShipmentBookingController.shipmentBookingDetail.checkoutPaymentMethod.toLowerCase() ===
      "tunai" && this.accountIsForeign
      ? "Cash"
      : ShipmentBookingController.shipmentBookingDetail.checkoutPaymentMethod;
  }

  get accountIsForeign() {
    return AccountController.accountData.accountIsForeign;
  }

  get isSttForeign() {
    return (
      ShipmentBookingController.shipmentBookingDetail.sttBookedByCountry.toUpperCase() ===
      "MY"
    );
  }

  get prefixCurrency() {
    return this.isSttForeign ? "RM" : "IDR";
  }
  prefixList = "T1,TKLP,TKP01,C1,C2,AD,ACA,ACB,AO,AI,ARA,ARB,AG";
  sttNumberPrefix = "19LP,98LP,99LP,95LP";

  onShowSectionAdjustmentWeight(shipmentNo: any, sttNo: any) {
    const resp = this.prefixList
      .split(",")
      .find((item: string) => shipmentNo.toUpperCase().startsWith(item));
    const respSttNo = this.sttNumberPrefix
      .split(",")
      .find((item: string) => sttNo.toUpperCase().startsWith(item));
    return !!resp || !!respSttNo;
  }

  onCheckDisableWeightAdjustment() {
    const findPrefix = this.prefixList
      .split(",")
      .find((item: string) =>
        this.manualBooking.sttShipmentId.toUpperCase().startsWith(item)
      );

    const findPrefixStt = this.sttNumberPrefix
      .split(",")
      .find((item: string) =>
        this.manualBooking.sttNo.toUpperCase().startsWith(item)
      );
    this.disabledWeightAdjustment = !!findPrefix || !!findPrefixStt;
  }

  disabledWeightAdjustment = false;

  showViewAdjustment = false;
  onClickWeightAdjustment() {
    const refs: any = this.$refs;
    this.showViewAdjustment = true;
    if (refs) {
      refs["section-weight-adjustment"].onClickAccordion();
    }
  }

  isErrorUpload = false;
  errorMessageUpload = "";
  get isDisableWeight() {
    const result = this.manualBooking.sttWeightAttachFiles.filter(
      (item: any) => item !== ""
    );
    return result.length < 2;
  }
  async upload(params: UploadMultiple) {
    MainAppController.closeMessageModal();
    MainAppController.closeErrorMessage();
    MainAppController.showLoading();
    try {
      if (params.file) {
        const fileLink = await BookingController.uploadHydraFile(
          new UploadHydraFileRequest({
            attachFile: params.file,
            folderPath: "stt/",
          })
        );
        this.manualBooking.sttWeightAttachFiles[params.index] = fileLink.data;
        this.disabledWeightAdjustment =
          this.manualBooking.sttWeightAttachFiles.filter(
            (key: any) => key !== ""
          ).length < 2;
      }
    } catch (err) {
      MainAppController.showErrorMessage(
        parsingErrorResponse(err, this.$t("bookingShipment.error.uploadPhoto"))
      );
    } finally {
      MainAppController.closeLoading();
    }
  }

  async remove(index: number) {
    MainAppController.closeMessageModal();
    MainAppController.closeErrorMessage();
    MainAppController.showLoading();
    try {
      await BookingController.deleteHydraFile(
        new DeleteHydraFileRequest({
          attachFiles: [this.manualBooking.sttWeightAttachFiles[index]],
        })
      );
      this.manualBooking.sttWeightAttachFiles[index] = "";
      const filteredRes = this.manualBooking.sttWeightAttachFiles.filter(
        (item: any) => item !== ""
      );
      this.disabledWeightAdjustment =
        this.manualBooking.sttWeightAttachFiles.filter((key: any) => key !== "")
          .length < 2;
      if (filteredRes.length < 2) {
        ShipmentBookingController.setSttPiecesToInitialValue();
        this.showWarningHws = false;
      }
    } catch (err) {
      MainAppController.showErrorMessage(
        parsingErrorResponse(err, "Gagal menghapus Foto ke storage!")
      );
    } finally {
      MainAppController.closeLoading();
    }
  }

  get eligibleToWoodpacking() {
    return this.formType === "client" && !this.manualBooking.sttShipmentId;
  }

  get woodpackingForshipment() {
    return (
      !!this.manualBooking.sttShipmentId &&
      FlagsMedusa.permission_woodpacking_shipment.isEnabled()
    );
  }

  get configHeavyWeightMinimum() {
    return HeavyWeightSurchargeController.heavyWeightMinimum;
  }

  async getConfigHws(product: any, commodity: any) {
    await HeavyWeightSurchargeController.getDetailMinimumHeavyWeightSurcharge({
      id: 1,
      product,
      commodity,
    });
    if (!this.configHeavyWeightMinimum?.minimumExist) {
      await HeavyWeightSurchargeController.getDetailMinimumHeavyWeightSurcharge(
        {
          id: 2,
          product,
          commodity,
        }
      );
    }
  }

  showWarningHws = false;

  get isErrorHws() {
    return this.listKGs.map((key: any) => key.errorHws).includes(true);
  }

  async onChangeCommodities() {
    this.checkFocus();
    if (
      this.manualBooking.sttProductTypeName &&
      this.manualBooking.sttCommodityName
    ) {
      await this.getConfigHws(
        this.manualBooking.sttProductTypeName,
        typeof this.manualBooking.sttCommodityName === "object"
          ? this.manualBooking.sttCommodityName?.code
          : ""
      );
      this.onSetErrorHws();
    }
  }

  onSetErrorHws() {
    this.showWarningHws =
      this.configHeavyWeightMinimum.minimumExist &&
      this.listKGs.length < 2 &&
      +this.listKGs[0]?.grossWeight?.toString().replace(",", ".") >=
        this.configHeavyWeightMinimum.heavyWeightMinimum;
    this.listKGs.forEach((key: any, index: number) => {
      this.listKGs[index].errorHws =
        this.configHeavyWeightMinimum.minimumExist &&
        +key.grossWeight?.toString()?.replace(",", ".") >=
          this.configHeavyWeightMinimum.heavyWeightMinimum &&
        this.listKGs.length > 1;
    });
  }

  setOnValidationHws(value: any, index = 0) {
    this.showWarningHws =
      this.configHeavyWeightMinimum.minimumExist &&
      this.listKGs.length < 2 &&
      +value?.replace(",", ".") >=
        this.configHeavyWeightMinimum.heavyWeightMinimum;
    this.listKGs[index].errorHws =
      this.configHeavyWeightMinimum.minimumExist &&
      this.listKGs.length > 1 &&
      +value?.replace(",", ".") >=
        this.configHeavyWeightMinimum.heavyWeightMinimum;
  }

  get isDisabledCommodity() {
    const isInternal =
      AccountController.accountData.account_type === "internal";
    const statuses = ["BKD", "PUP", "PUP-C", "STI", "STI-SC", "STO-SC"];
    const isMatchStatus = statuses
      .map(
        status =>
          status.toLowerCase() ===
          ShipmentBookingController.shipmentBookingDetail.sttLastStatus.toLowerCase()
      )
      .includes(true);

    return (isInternal && isMatchStatus) ||
      this.manualBooking.sttShipmentId !== "" ||
      this.manualBooking.sttNoRefExternal.startsWith("TKP01")
      ? false
      : this.isDisabledStatusByPod || true;
  }

  get isShowBanner() {
    return this.manualBooking.sttShipmentId === "";
  }
}
