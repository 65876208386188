
import { Options, prop, Vue } from "vue-class-component";
import {
  parsingErrorResponse,
  removeNewLineWhitespace,
} from "@/app/infrastructures/misc/Utils";
import { BookingController } from "@/app/ui/controllers/BookingController";
import {
  DeleteHydraFileRequest,
  UploadHydraFileRequest,
} from "@/data/payload/api/BookingRequest";
import { UploadMultiple } from "@/domain/entities/MainApp";
import { MainAppController } from "@/app/ui/controllers/MainAppController";

class Props {
  modelValue = prop<any>({
    required: true,
  });
  error = prop<any>({
    required: true,
  });
  errorCommodity = prop<boolean>({
    default: false,
    type: Boolean,
  });
  errorPhoto = prop<boolean>({
    default: false,
    type: Boolean,
  });
  commodity = prop<string>({
    default: "",
    type: String,
  });
  photos = prop<string[]>({
    default: [],
    type: Array,
  });
  currentIndexError = prop<number>({
    default: 0,
    type: Number,
  });
  indexErrorCommodity = prop<number>({
    default: 0,
    type: Number,
  });
  indexErrorPhoto = prop<number>({
    default: 0,
    type: Number,
  });
  limitError = prop<number>({
    default: 0,
    type: Number,
  });
}
@Options({
  emits: [
    "update:modelValue",
    "update:error",
    "updateCommodity",
    "updateFiles",
    "setFieldIndex",
  ],
  components: {
    // call your component here
  },
})
export default class SectionAdditionalInfoInterpack extends Vue.with(Props) {
  mounted() {
    this.$emit("update:error", {
      ...this.error,
      files: this.isErrorFile,
    });
  }
  replaceNewLine(value: string) {
    return removeNewLineWhitespace(value, " ");
  }
  setFieldIndex(index: number) {
    this.$emit("setFieldIndex", index);
  }

  validateEmail(value: string) {
    this.error.email = !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
    this.$emit("update:modelValue", {
      ...this.modelValue,
      email: value,
    });
    this.$emit("update:error", {
      ...this.error,
      email: this.error.email,
    });
  }

  validateCommodity(value: string) {
    this.error.commodity = !value;
    this.$emit("update:modelValue", {
      ...this.modelValue,
      commodity: value,
    });
    this.$emit("update:error", {
      ...this.error,
      commodity: this.error.commodity,
    });
  }
  attachmentImage: any = [];
  seedImages = new Array(4).fill("");
  storageImages: any = this.seedImages.map(
    (item: string, index: number) => this.photos[index]
  );

  isError = false;
  errorMessage = "";

  async upload(params: UploadMultiple) {
    MainAppController.closeMessageModal();
    MainAppController.closeErrorMessage();
    MainAppController.showLoading();
    this.setFieldIndex(this.indexErrorPhoto);
    this.isError = false;
    this.errorMessage = "";
    try {
      if (params.file) {
        const fileLink = await BookingController.uploadHydraFile(
          new UploadHydraFileRequest({
            attachFile: params.file,
            folderPath: "stt/",
          })
        );
        this.storageImages[params.index] = fileLink.data;
        this.updateFiles();
      }
    } catch (err) {
      this.isError = true;
      this.errorMessage = this.$t("bookingShipment.error.uploadPhoto", {
        value: params.index + 1,
      });
      MainAppController.showErrorMessage(
        parsingErrorResponse(err, this.$t("bookingShipment.error.uploadPhoto"))
      );
    } finally {
      MainAppController.closeLoading();
    }
  }

  get proofPhotos() {
    return [
      "Paket sebelum packing",
      "Paket sesudah packing",
      "<br/>ID Penerima",
      "<br/>Tax ID",
    ];
  }

  async remove(index: number) {
    MainAppController.closeMessageModal();
    MainAppController.closeErrorMessage();
    MainAppController.showLoading();
    this.isError = false;
    this.errorMessage = "";
    try {
      const del = await BookingController.deleteHydraFile(
        new DeleteHydraFileRequest({
          attachFiles: [this.storageImages[index]],
        })
      );
      const statusDel = del.data[this.storageImages[index]];
      statusDel ? this.deleteFiles(index) : this.erroMessageDelete(index);
    } catch (err) {
      this.erroMessageDelete(index);
    } finally {
      MainAppController.closeLoading();
    }
  }

  updateFiles() {
    this.$emit("update:modelValue", {
      ...this.modelValue,
      files: this.storageImages,
    });
    this.$emit("update:error", {
      ...this.error,
      files: this.isErrorFile,
    });
  }

  get isErrorFile() {
    return (
      this.modelValue.files.some(
        (value: any) => !value || value === "" || value === null
      ) || !this.modelValue.files.length
    );
  }

  deleteFiles(index: number) {
    this.storageImages[index] = "";
    this.updateFiles();
  }

  erroMessageDelete(index: number) {
    this.deleteFiles(index);
  }
}
