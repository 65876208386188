import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SelectGroupV2 = _resolveComponent("SelectGroupV2")!

  return (_openBlock(), _createBlock("div", null, [
    _createVNode(_component_SelectGroupV2, {
      dataSearch: _ctx.allCountry,
      onSearch: _ctx.onFilterCountry,
      code: _ctx.countryCode,
      "onUpdate:code": _cache[1] || (_cache[1] = ($event: any) => (_ctx.countryCode = $event)),
      customer: _ctx.selectedCustomer,
      "onUpdate:customer": _cache[2] || (_cache[2] = ($event: any) => (_ctx.selectedCustomer = $event)),
      phone: _ctx.phone,
      onSetValue: _ctx.setValueCountry,
      onPasteText: _ctx.pasteText,
      error: _ctx.error.error && _ctx.error.message !== '',
      errorCaption: _ctx.error.error ? _ctx.error.message : _ctx.$t('bookingShipment.error.invalidNumber'),
      maxLength: _ctx.maxLengthPhoneNumber(_ctx.phone),
      format: _ctx.formatNumber,
      onChange: _ctx.validatePhone,
      onFocus: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('focus'))),
      onEditItem: _ctx.editItem,
      isLoading: _ctx.isLoading,
      disabled: _ctx.disabled,
      options: _ctx.options,
      disabledUserDropdown: _ctx.disabledUserDropdown,
      keyValue: "customerPhoneNumber",
      keyName: "customerPhoneNumber",
      placeholder: "Contoh: 8123456789012",
      class: "mt-2"
    }, null, 8, ["dataSearch", "onSearch", "code", "customer", "phone", "onSetValue", "onPasteText", "error", "errorCaption", "maxLength", "format", "onChange", "onEditItem", "isLoading", "disabled", "options", "disabledUserDropdown"])
  ]))
}