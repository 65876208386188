import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  class: "\n      relative\n      flex flex-row\n      justify-between\n      items-center\n      z-20\n      rounded-t-lg\n      p-4\n    ",
  style: {"box-shadow":"0 0 20px #e0e0e0"}
}
const _hoisted_2 = { class: "flex flex-row gap-x-12 z-20 w-full" }
const _hoisted_3 = { class: "flex flex-grow gap-8" }
const _hoisted_4 = { class: "flex gap-2 items-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_weight_cost = _resolveComponent("weight-cost")!
  const _component_check_tariff_v2 = _resolveComponent("check-tariff-v2")!
  const _component_section_footer_detail_payment = _resolveComponent("section-footer-detail-payment")!
  const _component_select_payment_methods = _resolveComponent("select-payment-methods")!
  const _component_select_receipt_language_v_2 = _resolveComponent("select-receipt-language-v-2")!
  const _component_BookV2 = _resolveComponent("BookV2")!

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("div", _hoisted_2, [
      _createVNode("div", _hoisted_3, [
        _createVNode(_component_weight_cost, {
          formType: _ctx.formType,
          totalItem: _ctx.totalItem,
          totalGrossWeight: _ctx.totalGrossWeight,
          totalDimensionWeight: _ctx.totalDimensionWeight,
          chargeableWeight: _ctx.chargeableWeight,
          isVolumeWeight: _ctx.isVolumeWeight,
          isCampaign: _ctx.isCampaign,
          isGrossWeight: _ctx.isGrossWeight
        }, null, 8, ["formType", "totalItem", "totalGrossWeight", "totalDimensionWeight", "chargeableWeight", "isVolumeWeight", "isCampaign", "isGrossWeight"]),
        _createVNode(_component_check_tariff_v2, {
          isBooking: true,
          isDisabled: _ctx.isDisabled,
          bookingType: _ctx.formType,
          payload: _ctx.payload,
          accountRefAndCommodity: _ctx.accountRefAndCommodity,
          formType: _ctx.formType,
          discountData: _ctx.discount,
          isCodRetail: _ctx.isCodRetailApplied,
          goodsValue: _ctx.goodsValue,
          onUpdateStatusErrorEmbargo: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('updateStatusErrorEmbargo')))
        }, null, 8, ["isDisabled", "bookingType", "payload", "accountRefAndCommodity", "formType", "discountData", "isCodRetail", "goodsValue"])
      ]),
      _createVNode("div", _hoisted_4, [
        (_ctx.isCreateBookingRetail)
          ? (_openBlock(), _createBlock(_component_section_footer_detail_payment, {
              key: 0,
              isDiscount: _ctx.discount.status
            }, null, 8, ["isDiscount"]))
          : _createCommentVNode("", true),
        (_ctx.isCreateBookingRetail)
          ? (_openBlock(), _createBlock(_component_select_payment_methods, {
              key: 1,
              isDiscount: _ctx.discount.status
            }, null, 8, ["isDiscount"]))
          : _createCommentVNode("", true),
        (_ctx.isProductInterpack && _ctx.accountIsNonForeign)
          ? (_openBlock(), _createBlock(_component_select_receipt_language_v_2, {
              key: 2,
              customStyleList: _ctx.customStyleLanguage
            }, null, 8, ["customStyleList"]))
          : _createCommentVNode("", true),
        _createVNode(_component_BookV2, {
          isDisabled: _ctx.isDisabled,
          bookingType: _ctx.formType,
          payload: _ctx.payload,
          isDiscountActive: _ctx.discount.status,
          onSetDiscount: _ctx.setDiscount
        }, null, 8, ["isDisabled", "bookingType", "payload", "isDiscountActive", "onSetDiscount"])
      ])
    ])
  ]))
}